<template>
      <div v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.teamManagement" class="w-full flex justify-start pt-4 select-none text-sm">
        <span class="inline-flex items-center rounded-md bg-blue-100 px-2.5 py-1 font-medium text-blue-800">
         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
         </svg>
          <span class="pl-2">Limit {{userAmount}}/{{userLimit}}</span>
        </span>
      </div>
        <ul role="list" class="grid grid-cols-1 gap-6 mt-6 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4">
            <li v-for="(name,personIndx) in users" :key="personIndx" class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
                <div class="flex-1 flex flex-col p-8 select-none" :class="[users[personIndx].blocked !== undefined && users[personIndx].blocked ? 'opacity-40' : '']">
                <img class="w-32 h-32 flex-shrink-0 mx-auto rounded-full" :src="users[personIndx].photoURL" alt="">
                <h3 class="mt-6 text-gray-900 text-sm font-medium truncate">{{users[personIndx].name}} {{users[personIndx].surname}}</h3>
                <dl class="mt-1 flex-grow flex flex-col justify-between">
                    <dt class="sr-only">Company</dt>
                    <dd class="text-gray-500 text-sm truncate">{{users[personIndx].company}}</dd>
                    <dt class="sr-only">Role</dt>
                    <dd class="mt-3">
                    <span v-if="users[personIndx].systemCreator === true" class="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">Owner</span>
                    <span v-if="users[personIndx].systemCreator !== true" class="px-2 py-1 text-blue-800 text-xs font-medium bg-blue-100 rounded-full">User</span>
                    </dd>
                </dl>
                </div>
                <div :class="{'flex': users[personIndx].systemCreator === false && this.$store.state.userData.systemCreator === true}">
                <div :class="{'w-10/12': users[personIndx].systemCreator === false && this.$store.state.userData.systemCreator === true}">
                <!-- Normal email/call -->
                <div v-if="users[personIndx].blocked !== true" class="-mt-px flex divide-x divide-gray-200">
                    <div class="w-0 flex-1 flex">
                    <a :href="'mailto:'+users[personIndx].email" class="transition ease-in-out duration-150 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-white hover:bg-purple-400">
                        <!-- Heroicon name: solid/mail -->
                        <svg class="w-5 h-5 opacity-60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                        </svg>
                        <span class="ml-3">Email</span>
                    </a>
                    </div>
                    <div class="-ml-px w-0 flex-1 flex" v-if="users[personIndx].phoneNumber.length > 0">
                    <a :href="'tel:'+users[personIndx].phoneNumber" class="transition ease-in-out duration-150 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent hover:text-white hover:bg-purple-400" :class="{'rounded-br-lg': users[personIndx].systemCreator === true || this.$store.state.userData.systemCreator === false }">
                        <!-- Heroicon name: solid/phone -->
                        <svg class="w-5 h-5 opacity-60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                        </svg>
                        <span class="ml-3">Call</span>
                    </a>
                    </div>
                </div>
                <!-- Unlock button for systemCreator -->
                <div v-if="users[personIndx].blocked !== undefined && users[personIndx].blocked && !isActivating || users[personIndx].blocked !== undefined && users[personIndx].blocked && activUser !== personIndx" class=" flex divide-x divide-gray-200 transition-all duration-300 ">
                    <div class="w-0 flex-1 flex">
                    <a @click.prevent="activateUser(personIndx)" class="transition ease-in-out duration-150 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-white hover:bg-purple-400 select-none" :class="[this.activUser !== null || this.userAmount === this.userLimit ? 'cursor-not-allowed' : 'cursor-pointer']">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z" />
                      </svg>
                      <span class="ml-3">Activate</span>
                    </a>
                    </div>
                </div>
                <div v-if="users[personIndx].blocked !== undefined && users[personIndx].blocked && isActivating && activUser === personIndx" class="transition-all duration-300 flex divide-x divide-gray-200">
                    <div class="w-0 flex-1 flex">
                    <a class="transition ease-in-out duration-150 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg cursor-not-allowed select-none">
                      <svg class="w-5 h-5 animate-spin" fill="none"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              fill="currentColor"></path>
                      </svg>
                    </a>
                    </div>
                </div>
                <!-- Deactivate status for normal users -->
                <div v-if="users[personIndx].blocked !== undefined && users[personIndx].blocked && !this.$store.state.userData.systemCreator" class="flex divide-x divide-gray-200">
                    <div class="w-0 flex-1 flex">
                    <a class="transition ease-in-out duration-150 relative border-r w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-white hover:bg-red-500 cursor-not-allowed">
                        <!-- Heroicon name: solid/mail -->
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                        </svg>
                        <span class="ml-3">Deactivated</span>
                    </a>
                    </div>
                </div>
                </div>
                <div v-if="users[personIndx].systemCreator === false && this.$store.state.userData.systemCreator === true" class="w-2/12">
                <div class="flex divide-x divide-gray-200 h-full">
                    <div class="-ml-px w-0 flex-1 flex h-full">
                    <div @click="askForDelete(users[personIndx])" class="transition ease-in-out h-full duration-150 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-red-500 font-medium border-l rounded-br-lg cursor-pointer hover:text-white hover:bg-red-500">
                        <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </div>
                    </div>
                </div>
                </div>
                </div>
            </li>
            <li v-for="(invitation,personIndx) in pendingInvitations" :key="personIndx" class="transition ease-in-out duration-150 col-span-1 opacity-70 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 hover:opacity-100">
                <div class="flex-1 flex flex-col p-8">
                <img class="w-32 h-32 flex-shrink-0 mx-auto rounded-full" :src="invitation.img" alt="">
                <h3 class="mt-6 text-gray-900 text-sm font-medium truncate">{{invitation.name}} {{invitation.surname}}</h3>
                <dl class="mt-1 flex-grow flex flex-col justify-between">
                    <dt class="sr-only">Email</dt>
                    <dd class="text-gray-500 text-sm truncate">{{invitation.email}}</dd>
                    <dt class="sr-only">Invitation status</dt>
                    <dd class="mt-3">
                    <span class="px-2 py-1 text-gray-800 text-xs font-medium bg-gray-200 rounded-full">Invitation pending...</span>
                    </dd>
                </dl>
                </div>
                <div class="flex divide-x divide-gray-200">
                  <div class="-mt-px flex divide-x divide-gray-200 flex-1" v-if="this.$store.state.userData.systemCreator === true && !isSending" >
                      <div class="w-0 flex-1 flex">
                      <div @click.prevent="sendAgainInvitation(invitation)" class="cursor-pointer transition ease-in-out duration-150 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-white hover:bg-purple-400">
                          <!-- <svg class="w-5 h-5 opacity-60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg> -->
                          <svg class="w-5 h-5 opacity-60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg>
                          <span class="ml-3">Send again</span>
                      </div>
                      </div>
                  </div>
                  <div class="-mt-px flex divide-x divide-gray-200 flex-1" v-if="this.$store.state.userData.systemCreator === true && isSending">
                      <div class="w-0 flex-1 flex">
                      <div class="cursor-not-allowed transition ease-in-out duration-150 relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-white hover:bg-purple-400">
                          <svg class="w-5 h-5 animate-spin" fill="none"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75"
                                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  fill="currentColor"></path>
                          </svg>
                      </div>
                      </div>
                  </div>
                  <div class="-mt-px flex divide-x divide-gray-200 flex-1" v-if="this.$store.state.userData.systemCreator === true">
                      <div class="w-0 flex-1 flex">
                      <div @click="askForCancel(invitation)" class="cursor-pointer transition ease-in-out duration-150 relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-red-500 font-medium border border-transparent rounded-br-lg hover:text-white hover:bg-red-400">
                          <!-- <svg class="w-5 h-5 opacity-60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                          <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                          </svg> -->
                          <svg class="w-5 h-5 opacity-60" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                          <span class="ml-3">Cancel</span>
                      </div>
                      </div>
                  </div>
                </div>
            </li>
        </ul>
      

        <Transition>

<div v-if="deleteModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Are you sure to delete {{this.toDelete.name}}?</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">Are you sure you want to delete {{this.toDelete.name}} account? All of account data will be removed permanently. This action cannot be undone.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="deleteUser()" type="button" class="transition ease-in-out duration-150 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto text-sm">Delete</button>
          <button @click="cancelDelete()" type="button" class="transition ease-in-out duration-150 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto text-sm">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
</Transition>



<Transition>
<div v-if="invitationCancelModal" class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">

  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed z-10 inset-0 overflow-y-auto">
    <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Are you sure to cancel {{this.invitationCancel.name}} invitation?</h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">Are you sure you want to cancel {{this.invitationCancel.name}} invitation? All of account data will be removed permanently. This action cannot be undone.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button @click="cancelInvitation()" type="button" class="transition ease-in-out duration-150 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:w-auto text-sm">Delete</button>
          <button @click="leaveCancelInvitation()" type="button" class="transition ease-in-out duration-150 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto text-sm">Cancel</button>
        </div>
      </div>
    </div>
  </div>
  
</div>

</Transition>
</template>
<script>
import axios from 'axios';
import {db} from '@/firebase/gfbconf.js';
    export default {
        name: "TeamList",
        components: {},
        data: function () {
            return {
                users: [],
                isSending: false,
                isActivating: false,
                activUser: null,
                toDelete: {
                    name: "",
                    id: ""
                },
                invitationCancel: {
                    name: "",
                    id: ""
                },
                deleteModal: false,
                invitationCancelModal: false,
                pendingInvitations: []
            }
        },
        watch: {
        // whenever question changes, this function will run
            refreshList(newQuestion) {
              if (newQuestion === true) {
                this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: "Loading your team..",
                        subheader: `Do not close this tab.`,
                    });
                this.downloadUsers()
                this.$emit('listReseted')
              }
            }
          },
        props: ['refreshList'],
        computed: {
          userAmount() {
            let userAmount = 0
            this.users.forEach(user => {
                let blocked = user.blocked === undefined ? false : user.blocked
                if(!blocked) {
                  userAmount++
                }
            })
            return userAmount
          },
          userLimit() {
            return eval(this.$store.state.userSubscription.items[0].price.product.metadata.userAmount)
          }
        },
        methods: {
          async downloadSystemDataAgain()
          {
            const sysResult = await this.$store.state.userData.system.ref.get();
            if(sysResult.exists)
            {
              const d = sysResult.data();
              this.$store.state.systemData = d;
            }
          },
          async activateUser(userIndex) {
            if(this.activUser === null && this.userAmount < this.userLimit) {
              try {
                this.isActivating = true
                this.activUser = userIndex
                const activationResult = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/initialize/activateUser', {
                  userId: this.users[userIndex].id,
                });

                if(activationResult.data.status === 'success') {
                  this.$store.commit('setNotification',{
                      show: true,
                      head: "User has been activated",
                      subheader: `Now ${this.users[userIndex].name} ${this.users[userIndex].surname} can sign in.`,
                      success: true
                  })
                  this.downloadUsers();
                  this.downloadSystemDataAgain();
                }
                this.isActivating = false
                this.activUser = null
              } catch(error) {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Oops! Something went wrong.",
                    subheader: `${error}`,
                    success: false
                })
                this.isActivating = false
                this.activUser = null
              }
            }
          },
          async cancelInvitation(){
                this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: `Removing invitation ${this.invitationCancel.name}`,
                        subheader: `Do not close this tab.`,
                    });
                const deleteResult = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/setup/initialize/deleteInvitation', {
                  invitationId: this.invitationCancel.id,
                });
              if(deleteResult.data.status === 'success')
                {
                this.invitationCancel = {
                    name: "",
                    id: ""
                }
                this.invitationCancelModal = false;
                this.downloadUsers();
                this.downloadSystemDataAgain();
                this.$store.commit('setNotification',{
                            show: true,
                            head: "Invitation has been deleted!",
                            subheader: `You deleted invitation to your system.`,
                            success: true
                        })
                }else{
                  this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: `Ups going back..`,
                        subheader: `Cannot cancel invitation.`,
                    });
                  this.deleteModal = false;
                  setTimeout(()=>
                  {
                    this.$store.commit("resetGlobalLoader");
                    this.$store.commit('setNotification',{
                            show: true,
                            head: "Delete user error!",
                            subheader: `Cannot cancel invitation, please try again later.`,
                            success: false
                        })
                  },5000);
                }

            },
            leaveCancelInvitation(){
                this.invitationCancel = {
                    name: "",
                    id: ""
                }
                this.invitationCancelModal = false
            },
            async sendAgainInvitation(invitation) {
              try {
                this.isSending = true;
                await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/sendEmail", {
                  attendantEmail: this.$store.state.user.email,
                  to: invitation.email,
                  messageType: "teamInvite",
                  payload: {
                    inviterName: this.$store.state.user.displayName,
                    companyName: this.$store.state.systemData.company.name,
                    invitedName: invitation.name,
                    invitedSurname: invitation.surname,
                    invitationLink: `https://app.complaia.com/invitation?id=${invitation.id}`
                  }
                })
                setTimeout(()=>{
                  this.isSending = false;
                  this.$store.commit('setNotification',{
                        show: true,
                        head: "Success!",
                        subheader: `The invitation was successfully sent.`,
                        success: true
                    });
                  },100);
              }
              catch(error) {
                this.isSending = false;
                this.$store.commit('setNotification',{
                      show: true,
                      head: "Failure.",
                      subheader: `Invitation has not been sent. Try again later.`,
                      success: false
                  });    
                }
            },
            askForCancel(invitation){
              this.invitationCancelModal = true
              this.invitationCancel.name = invitation.name + ' ' +invitation.surname
              this.invitationCancel.id = invitation.id
            },
            async deleteUser(){
                this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: `Removing user ${this.toDelete.name}`,
                        subheader: `Do not close this tab.`,
                    });
                const deleteResult = await axios.post('https://europe-central2-complaia.cloudfunctions.net/api/setup/initialize/deleteUser', {
                  userId: this.toDelete.id,
                  systemId: this.$store.state.userData.system.id
                });
              if(deleteResult.data.status === 'success')
                {
                this.toDelete = {
                    name: "",
                    id: ""
                }
                this.deleteModal = false;
                this.downloadUsers();
                this.downloadSystemDataAgain();
                this.$store.commit('setNotification',{
                            show: true,
                            head: "User has been deleted!",
                            subheader: `You deleted user from your system.`,
                            success: true
                        })
                }else{
                  this.$store.commit('setGlobalLoader',{
                        show: true,
                        head: `Ups going back..`,
                        subheader: `Cannot delete account.`,
                    });
                  this.deleteModal = false;
                  setTimeout(()=>
                  {
                    this.$store.commit("resetGlobalLoader");
                    this.$store.commit('setNotification',{
                            show: true,
                            head: "Delete user error!",
                            subheader: `Cannot delete user, please try again later.`,
                            success: false
                        })
                  },5000);
                }

            },
            cancelDelete(){
                this.toDelete = {
                    name: "",
                    id: ""
                }
                this.deleteModal = false
            },
            askForDelete(user){
                this.deleteModal = true
                this.toDelete.name = user.name + ' ' +user.surname
                this.toDelete.id = user.id
            },
            async downloadUsers(){
                this.pendingInvitations = [];
                this.users = [];
                const result = await this.$store.state.userData.system.ref.get();
                if(this.$store.state.userData.systemCreator === true){
                  const invitations = await db.collection("Invitations").where("system", "==", `${this.$store.state.userData.system.id}`).get();
                  if(invitations.docs.length > 0){
                    for(let z=0; z<invitations.docs.length; z++){
                      let invitationData = invitations.docs[z].data()
                      invitationData.id = invitations.docs[z].id
                      invitationData.img = `https://avatars.dicebear.com/api/initials/${encodeURIComponent(invitationData.name+" "+invitationData.surname)}.svg`
                      this.pendingInvitations.push(invitationData)
                    }
                  }
                }
                const data = result.data();
                let refs = []
                for(let i=0; i<data.users.length; i++){
                    refs.push(data.users[i].ref.get())
                }
                const userResult = await Promise.all(refs);
                userResult.forEach((doc)=>{
                    let userData = doc.data()
                    userData.id = doc.id
                    this.users.push(userData)
                })
                setTimeout(() => {
                  this.$store.commit("resetGlobalLoader");
                }, 200)
            }
        },
        created()
        {
            this.downloadUsers();
            this.$store.commit('setGlobalLoader',{
                show: true,
                head: "Loading your team..",
                subheader: `Do not close this tab.`,
            });
        }
    };
</script>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}


</style>