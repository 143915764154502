<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Add new client </DialogTitle>

                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Create a new client to Complaia database.</p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="divide-y divide-gray-200 px-4 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <fieldset>
                          <legend class="text-sm font-medium text-gray-900">Type</legend>
                          <div class="mt-2 space-y-5">
                            <div class="relative flex items-start">
                              <div class="absolute flex h-5 items-center" >
                                <input id="privacy-public" v-model="client.type" value="Client" name="privacy" aria-describedby="privacy-public-description" type="radio" class="h-4 w-4 border-gray-300 text-purple-600 focus:ring-purple-500 cursor-pointer" />
                              </div>
                              <div class="pl-7 text-sm">
                                <label for="privacy-public" class="font-medium text-gray-900 cursor-pointer">Client</label>
                              </div>
                            </div>
                            <div>
                              <div class="relative flex items-start">
                                <div class="absolute flex h-5 items-center">
                                  <input id="privacy-private-to-project" v-model="client.type" value="Company" name="privacy" aria-describedby="privacy-private-to-project-description" type="radio" class="h-4 w-4 border-gray-300 cursor-pointer text-purple-600 focus:ring-purple-500" />
                                </div>
                                <div class="pl-7 text-sm">
                                  <label for="privacy-private-to-project" class="font-medium text-gray-900 cursor-pointer"> Company </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <div>
                          <label for="project-name" class="block text-sm font-medium text-gray-900"> Name* </label>
                          <div class="mt-1">
                            <input type="text" v-model="client.name" v-on:blur="checkIfNameExsistInDb" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                          </div>
                          <OrangeAlert v-if="this.warnings.clientWithSameName" class="mt-3" :alert="`${warnings.clientWithSameNameSave} might already exsist.`"></OrangeAlert>
                        </div>
                        <div>
                          <label for="project-name" :class="{'block text-sm font-medium text-gray-900': true, 'text-red-600': !checkEmail && client.email.length>0 }"> E-mail* </label>
                          <div class="mt-1">
                            <input type="text" v-model="client.email" v-on:blur="checkIfEmailExsistInDb" :class="{'block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm': true, 'border-red-500 focus:ring-red-500 focus:border-red-500 ': !checkEmail && client.email.length>0}" />
                          </div>
                          <OrangeAlert v-if="this.warnings.clientWithSameEmail" class="mt-3" :alert="`Client with this email already exsist.`"></OrangeAlert>
                        </div>
                        <div>
                          <label for="project-name" class="block text-sm font-medium text-gray-900"> Phone </label>
                          <div class="mt-1">
                            <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' v-model="client.phoneNum" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                          </div>
                        </div>
                        <div>
                          <label for="project-name" class="block text-sm font-medium text-gray-900"> Address </label>
                          <div class="mt-1">
                            <input type="text" v-model="client.address" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                          </div>
                        </div>
                        <div>
                          <label for="project-name" class="block text-sm font-medium text-gray-900"> City </label>
                          <div class="mt-1">
                            <input type="text" v-model="client.city" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                          </div>
                        </div>
                        <div>
                          <label for="project-name" class="block text-sm font-medium text-gray-900"> Post Code </label>
                          <div class="mt-1">
                            <input type="text" v-model="client.postCode" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                          </div>
                        </div>
                        <div>
                          <label for="project-name" class="block text-sm font-medium text-gray-900"> Country </label>
                          <div class="mt-1">
                            <input type="text" v-model="client.country" class="block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                          </div>
                        </div>
                        <div>
                          <label for="description" class="block text-sm font-medium text-gray-900"> Description </label>
                          <div class="mt-1">
                            <textarea id="description" v-model="client.description" rows="2" class="block w-full rounded-md border border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm" />
                          </div>
                        </div>


                      </div>
                      <div class="pt-4 pb-6">
                        <div class="mt-4 flex text-sm">
                          <div class="group inline-flex items-center text-gray-500 cursor-help">
                            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400 cursor-help" aria-hidden="true" />
                            <span class="ml-2"> This client will be available in complaint creator. </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <button @click.prevent="emitClose" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Cancel</button>
                  <button @click.prevent="createNewClient" :class="{'ml-4 inline-flex justify-center rounded-md border border-transparent bg-purple-400 py-2 px-4 text-sm font-medium text-white shadow-sm  focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2': true, 'bg-purple-600 hover:bg-purple-700': client.name.length>0 && client.email.length>0 && checkEmail}">Save</button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import OrangeAlert from '@/components/OrangeAlert.vue';
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { QuestionMarkCircleIcon } from '@heroicons/vue/solid'
    import {db} from "@/firebase/gfbconf.js"
    export default {
        name: "ClientsModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            OrangeAlert,
            QuestionMarkCircleIcon,
        },
        data: function () {
            return {
                warnings: {
                  clientWithSameName: false,
                  clientWithSameNameSave: "",
                  clientWithSameEmail: false
                },
                emailExists: false,
                open: false,
                // If you change something bellow, edit it also in NewComplaint.vue when creating new client
                client: {
                    type: "Client",
                    name: "",
                    email: "",
                    phoneNum: "",
                    address: "",
                    city: "",
                    postCode: "",
                    description: "",
                    country: "",
                    editHistory: [],
                    complaints: []
                }
            }
        },
        methods: {
            async checkIfEmailExsistInDb()
            {
              if(this.client.email.length>0 && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.client.email))
              {
                const emailResult = await this.$store.state.userData.system.ref.collection("Clients").where("email", "==", this.client.email).get().catch((error)=>
                {
                  console.log(error);
                })
                if(emailResult.empty)
                {
                  this.warnings.clientWithSameEmail = false;
                }
                else
                {
                  this.warnings.clientWithSameEmail = true;
                }
              }
              else
              {
                this.warnings.clientWithSameEmail = false;
              }
            },
            async checkIfNameExsistInDb()
            {
              if(this.client.name.length>0)
              {
                const nameResult = await this.$store.state.userData.system.ref.collection("Clients").where("name", "==", this.client.name).get().catch((error)=>
                {
                  console.log(error);
                })
                if(nameResult.empty)
                {
                  this.warnings.clientWithSameName = false;
                  this.warnings.clientWithSameNameSave = "";
                }
                else
                {
                  this.warnings.clientWithSameName = true;
                  this.warnings.clientWithSameNameSave = this.client.name;
                }
              }
              else
              {
                this.warnings.clientWithSameName = false;
                this.warnings.clientWithSameNameSave = "";
              }
            },
            clearData()
            {
                this.client = {
                    type: "Client",
                    name: "",
                    email: "",
                    phoneNum: "",
                    address: "",
                    city: "",
                    postCode: "",
                    description: "",
                    country: "",
                    editHistory: [],
                    complaints: []
                };
                this.warnings.clientWithSameEmail = false;
                this.warnings.clientWithSameName = false;
            },
            // Reset searchphrase
            emitClose()
            {
              
                this.$emit("closeClientModal");
                this.clearData();
            },
            // Create new client
            async createNewClient()
            {
                // Check if email is OK
                if(this.client.name.length>0 && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.client.email))
                {

                    
                    try {
                        this.emailExists = false;
                        this.client.creationDate = moment().toDate();
                        this.client.createdBy = this.$store.state.user.displayName;
                        // Add Client to elastic search index
                        const elasticResponse = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/add/client", {
                          doc: {
                            name: this.client.name,
                            email: this.client.email,
                            phoneNum: this.client.phoneNum
                          },
                          system: this.$store.state.userData.system.id
                        }).catch((error)=>
                        {
                          console.log(error);
                        });
                        // Update data from elastic response inside client
                        if(elasticResponse.data.status === "ok")
                        {
                          this.client.elasticSearch = elasticResponse.data.elasticSearch;
                        }
                        else
                        {
                          this.client.elasticSearch = {
                            docId: "",
                            index: "",
                            creationDate: ""
                          };
                        }

                    let preparedClientName = this.client.name.trim().split(/\s+/)
                    preparedClientName[0] = preparedClientName[0] === undefined ? 'User' : preparedClientName[0]
                    preparedClientName[1] = preparedClientName[1] === undefined ? '' : preparedClientName[1]
                    this.client.photoUrl = `https://avatars.dicebear.com/api/initials/${encodeURIComponent(preparedClientName[0]+" "+preparedClientName[1])}.svg`
                        // Add client to Firestore
                        const newClientResponse = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").add(this.client);
                        this.$store.state.userData.system.ref.collection("Events").add(
                          {
                            user: {
                              name: this.$store.state.user.displayName,
                              photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `Added new client ${this.client.name}.`
                          }).catch((error)=>
                          {
                            console.log(error);
                          });
                        // Update field id in client document
                        db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").doc(newClientResponse.id).update({
                          id: newClientResponse.id
                        }).catch((error)=>
                        {
                          console.log(error);
                        });
                        // Close tab
                        this.emitClose();
                        // Show Notification
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Client added!",
                            subheader: `Client has been created successfully.`,
                            success: true
                        });

                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Cannot create new client.`,
                            success: false
                        });
                    }
                }
            }
        },
        computed:{
            checkEmail()
            {
                if(this.client.email.length > 0)
                {
                    if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.client.email))
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
                else
                {
                    return true
                }
            }
        }
    };
</script>