<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div v-if="loaded">
    <div class="flex sm:items-baseline sm:space-x-4 mb-4">
      <div @click.prevent="previousPage" class="text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span><span> Back</span></div>
    </div>
    <div>
      <h3 class="text-lg leading-6 font-medium text-gray-900">Client information</h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">Personal details about your client.</p>
    </div>
    <div class="grid gap-x-2 gap-y-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 mt-5">
      <button v-if="client.email.length>0" @click.prevent="sendEmail" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
        <MailIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        Send email
      </button>
      <button v-if="client.phoneNum.length>0" @click.prevent="call" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
        <PhoneOutgoingIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        Call
      </button>
      <button v-if="oneLineAddress.length>0" @click.prevent="showInGoogleMaps" type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
        <MapIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        Show in Google Maps
      </button>
      <button type="button" v-if="evaluate(this.$store.state.userSubscription.items[0].price.product.metadata.printableLabels)" @click.prevent="printLabel()" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
        <PrinterIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
       Print Label
      </button>
      <button @click="this.$router.push(`/dashboard/complaints/new?client=${this.client.id}`)"  type="button" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-500 hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
        <PlusCircleIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
       Add new complaint
      </button>
      <button :disabled="client.complaints.length > 0" :class="[client.complaints.length > 0 ? 'cursor-not-allowed bg-red-600 opacity-50 text-white' : 'text-white cursor-pointer bg-red-600 hover:bg-red-700 focus:ring-red-500']" type="button" @click.prevent="deleteAsk = true" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ">
        <TrashIcon class="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
       Delete
      </button>
    </div>



    <div v-if="client.complaints.length > 0" class="max-w-max text-xs mb-3 mt-3 text-yellow-500 cursor-none select-none flex bg-yellow-100 p-1 rounded-lg">
        <svg aria-hidden="true" class="w-4 h-4 text-yellow-500 dark:text-yellow-600" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
        <span style="margin-left: 0.15rem;">If you want to remove a customer, you must delete all complaints related to the customer</span>
    </div>
    <div class="flex flex-col sm:flex-row sm:justify-end" v-if="!editMode && $store.state.userData.systemCreator || $store.state.userData.permissions.editingCustomers">
      <button type="button" v-show="!editMode" @click.prevent="toggleEditMode" class="inline-flex items-center justify-center mb-2 sm:mb-0 px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
        Edit
        <PencilIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
      </button>
    </div>
    <div class="mt-5 border-t border-gray-200">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" id="editName">
          <dt v-if="!editMode" class="block text-sm font-medium text-gray-500">Name</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!editMode">{{client.name}}</span>
            <div v-if="editMode" :class="{'relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600': true, 'border-red-600': checkName === false}">
              <label for="name" :class="{'absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900': true, 'text-red-600': checkName === false}">Name*</label>
              <input type="text" name="name" v-model="editedClientData.name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="Name" />
            </div>
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt v-if="!editMode" class="block text-sm font-medium text-gray-500">Type</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!editMode">{{client.type}}</span>
            <div v-if="editMode">
              <label for="desc" class="block text-xs font-medium text-gray-900 mb-1">Select type</label>
              <!-- OLD VERSION -->
              <!-- <select v-model="editedClientData.type" class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-purple-500 focus:border-purple-500 text-sm rounded-md">
                <option>Client</option>
                <option>Company</option>
              </select> -->
              <Listbox as="div" v-model="editedClientData.type">
                <div class="relative">
                    <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                        <span class="flex items-center truncate">{{`${editedClientData.type}`}}</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>

                    <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                        <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                        <ListboxOption as="template" value="Client" v-slot="{ active, selected }">
                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Client</span>

                            <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                            </li>
                        </ListboxOption>
                        <ListboxOption as="template" value="Company" v-slot="{ active, selected }">
                            <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                            <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">Company</span>

                            <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                            </li>
                        </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>
            </div>
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt v-if="!editMode" class="block text-sm font-medium text-gray-500">Email address</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!editMode">{{client.email}}</span>
            <div v-if="editMode" :class="{'relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600': true, 'border-red-600': checkName === false}">
              <label for="name" :class="{'absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900': true, 'text-red-600': checkEmail === false}">Email*</label>
              <input type="text" v-model="editedClientData.email" name="name" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="Email" />
            </div>            
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt v-if="!editMode" class="block text-sm font-medium text-gray-500">Phone number</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!editMode">{{client.phoneNum}}</span>
            <span v-if="client.phoneNum.length === 0 && !editMode" class="text-gray-400">Not provided</span>
            <div v-if="editMode" class="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600">
              <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Phone</label>
              <input type="text" name="name" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' v-model="editedClientData.phoneNum" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="Phone Number" />
            </div>          
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt v-if="!editMode" class="block text-sm font-medium text-gray-500">Address</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!editMode">{{oneLineAddress}}</span>
            <span v-if="oneLineAddress.length === 0 & !editMode" class="text-gray-400">Not provided</span>
            <div v-if="editMode" class="relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600">
              <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Address</label>
              <input type="text" name="name" v-model="editedClientData.address" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="Address" />
            </div>
            <div v-if="editMode" class="relative border border-gray-300 rounded-md px-3 mt-6 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600">
              <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">City</label>
              <input type="text" name="name" v-model="editedClientData.city" id="name" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="City" />
            </div>
            <div v-if="editMode" class="relative border border-gray-300 rounded-md px-3 mt-6 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600">
              <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Post Code</label>
              <input type="text" name="name" id="name" v-model="editedClientData.postCode" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="Post Code" />
            </div>
            <div v-if="editMode" class="relative border border-gray-300 rounded-md px-3 mt-6 py-2 shadow-sm focus-within:ring-1 focus-within:ring-purple-600 focus-within:border-purple-600">
              <label for="name" class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900">Country</label>
              <input type="text" name="name" id="name" v-model="editedClientData.country" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 text-sm" placeholder="Country" />
            </div> 
          </dd>
        </div>
        <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
          <dt v-if="!editMode" class="block text-sm font-medium text-gray-900">Description</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <span v-if="!editMode">{{client.description}}</span>
            <span v-if="client.description.length === 0 && !editMode" class="text-gray-400">Not provided</span>
            <div v-if="editMode">
              <label for="desc" class="block text-xs font-medium text-gray-700">Description</label>
              <div class="mt-1">
                <textarea rows="4" name="desc" id="desc" placeholder="Description" v-model="editedClientData.description" class="shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm border-gray-300 rounded-md" />
              </div>
            </div>
          </dd>
        </div>
      </dl>
      <div class="flex flex-col sm:flex-row sm:justify-end" v-if="!editMode && $store.state.userData.systemCreator || $store.state.userData.permissions.editingCustomers">
        <div class="flex flex-col sm:flex-row" v-if="editMode">
          <button type="button" @click.prevent="toggleEditMode" class="mb-2 sm:mb-0 inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
            Discard changes 
            <BanIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
          </button>
          <button type="button" @click.prevent="updateClient" :class="{'sm:ml-4 inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-700 focus:ring-green-500': true, 'opacity-100' : checkName === true && checkEmail === true, 'opacity-30' : checkName === false || checkEmail === false}">
            Save changes 
            <CheckIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
          </button> 
        </div> 
      </div>
      <div class="text-xs mt-4 mb-4 text-gray-400">
        {{createdBy}}
      </div>

      <!-- <div class="flex justify-end" v-if="editMode">
        <button type="button" @click.prevent="toggleEditMode" class="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
          Discard changes 
          <BanIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
        </button>
        <button type="button" @click.prevent="updateClient" :class="{'ml-4 inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 bg-green-600 hover:bg-green-700 focus:ring-green-500': true, 'opacity-100' : checkName === true && checkEmail === true, 'opacity-30' : checkName === false || checkEmail === false}">
          Save changes 
          <CheckIcon class="ml-2 -mr-0.5 h-4 w-4" aria-hidden="true" />
        </button>
      </div> -->
      
        <div class="mt-4 mb-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">Complaints</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">All complaints that belongs to this client.</p>
        </div>

      <ComplaintList class="mt-2" :complaints="client.complaints"></ComplaintList>
      <EmptyState v-if="client.complaints.length === 0"></EmptyState>

      <askForDelete v-if="deleteAsk" @accept="deleteClientFromDatabase" :modalData="modalData" @closeModal="deleteAsk = false"></askForDelete>
    </div>
  </div>
  <EditClientModal></EditClientModal>
</template>

<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import axios from 'axios';
import moment from 'moment';
import askForDelete from '@/components/AlertModal.vue';
import EmptyState from "@/components/EmptyState.vue"
import ComplaintList from "@/components/ClientActiveComplaintList.vue";
import { MailIcon, PhoneOutgoingIcon, PrinterIcon, MapIcon , PlusCircleIcon, TrashIcon, CheckIcon, BanIcon, PencilIcon } from '@heroicons/vue/outline';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { SelectorIcon} from '@heroicons/vue/solid'

export default {
    data: function () {
    return {
      loaded: true,
      editMode: false,
      deleteAsk: false,
      editedClientData: null,
      noData: '---',
      modalData: {
        headerText: "Delete client",
        bodyText: "Are you sure to delete selected client? Data will be removed permanently.",
        acceptButtonText: "Agree"
      }
    }
  },
  components: {
    ComplaintList,
    // ChevronLeftIcon,
    MailIcon,
    PhoneOutgoingIcon,
    PrinterIcon,
    MapIcon,
    PlusCircleIcon,
    TrashIcon,
    BanIcon,
    PencilIcon,
    askForDelete,
    CheckIcon,
    // ActiveList,
    EmptyState,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    SelectorIcon
  },
  props:['client'],
  computed: {

    checkName()
    {
      if(this.editedClientData.name.length>0)
      {
        return true;
      }
      else
      {
        return false;
      }
    },
    checkEmail()
    {
      if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.editedClientData.email))
      {
        return true;
      }
      else
      {
        return false;
      }
    },
    createdBy()
    {
      return `Client created by ${this.client.createdBy} - ${moment(this.client.creationDate.seconds*1000).format("DD.MM.YYYY")}`
    },
    // Returns address one-liner
    oneLineAddress()
    {
      let addressArr = [];
      if(this.client.address.length>0)
      {
        addressArr.push(this.client.address)
      }
      if(this.client.city.length>0)
      {
        addressArr.push(this.client.city)
      }
      if(this.client.postCode.length>0)
      {
        addressArr.push(this.client.postCode)
      }
      if(this.client.country.length>0)
      {
        addressArr.push(this.client.country)
      }

      let addressText = addressArr.join(', ');

      return addressText;
    }
  },
  methods:
  {
    evaluate(string) {
      return eval(string)
    },
    checkFontSize(string, type) {
      let fontSize = 12
                    // TESTED ON LARGE "W"
                    // 12px-15letters
                    // 10px-18letters
                    // 8px-22letters
                    // 6px-30letters
                    // else CUT
      if(!type) {
        if(string.length > 23) {
          fontSize = 10
        } 
        if (string.length > 27) {
          fontSize = 8
        }
      }
      if(type) {
        if(string.length > 15) {
          fontSize = 10
        } 
        if (string.length > 22) {
          fontSize = 8
        }
      }
      return fontSize
    },
    printLabel() {
      var dd = {
        pageSize: "A6",
        pageMargins: [20, 30, 20, 30],

        content: [
          {
            table: {
              widths: ["33.33%", "33.33%", "33.33%"],
              body: [
                [
                  {
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "Name",
                    alignment: "left",
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    border: [true, false, true, true],
                    text: { text: this.client.name ? { text: this.client.name.length > 42 ? this.client.name.slice(0,42)+'...' : this.client.name } : { text: this.noData } },
                    fontSize: this.checkFontSize(this.client.name, false),
                    alignment: "left",
                    colSpan: 3,
                    margin: [0, 0, 0, 2],
                  },
                ],
                [
                  {
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "Info",
                    alignment: "left",
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    border: [true, false, true, true],
                    text: { text: '' },
                    fontSize: 12,
                    alignment: "left",
                    colSpan: 3,
                    margin: [2, 16, 2, 2],
                  },
                ],
                [
                  {
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "Address",
                    alignment: "left",
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    border: [true, false, true, true],
                    text: { text: this.client.address ? { text: this.client.address.length > 42 ? this.client.address.slice(0,42)+'...' : this.client.address } : { text: this.noData } },
                    fontSize: this.checkFontSize(this.client.address, false),
                    alignment: "left",
                    colSpan: 3,
                    margin: [0, 0, 0, 2],
                  },
                ],
                [
                  {
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "Post Code",
                    alignment: "left",
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    border: [true, false, true, true],
                    text: { text: this.client.postCode ? { text: this.client.postCode.length > 42 ? this.client.postCode.slice(0,42)+'...' : this.client.postCode } : { text: this.noData } },
                    fontSize: this.checkFontSize(this.client.postCode, false),
                    alignment: "left",
                    colSpan: 3,
                     margin: [0, 0, 0, 2],
                  },
                ],
                [
                  {
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "City",
                    alignment: "left",
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    border: [true, false, true, true],
                    text: { text: this.client.city ? { text: this.client.city.length > 42 ? this.client.city.slice(0,42)+'...' : this.client.city } : { text: this.noData } },
                    fontSize: this.checkFontSize(this.client.city, false),
                    alignment: "left",
                    colSpan: 3,
                    margin: [0, 0, 0, 2],
                  },
                ],
                [
                  {
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "Country",
                    alignment: "left",
                    colSpan: 3,
                  },
                  {},
                  {},
                ],
                [
                  {
                    border: [true, false, true, true],
                    text: { text: this.client.country ? { text: this.client.country.length > 42 ? this.client.country.slice(0,42)+'...' : this.client.country } : { text: this.noData } },
                    fontSize: this.checkFontSize(this.client.country, false),
                    alignment: "left",
                    colSpan: 3,
                     margin: [0, 0, 0, 2],
                  },
                ],
                [
                  {
                    border: [true, true, true, true],
                    qr: this.client.name,
                    foreground: "black",
                    background: "white",
                    alignment: "center",
                    fit: "70",
                    version: 4,
                    rowSpan: 4,
                    margin: [0, 6, 0, 6],
                  },
                  {
                    colSpan: 2,
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "Phone Number",
                    alignment: "left",
                    margin: [0, 4, 0, -2],
                  },
                  {},
                ],
                [
                  {},
                  {
                    style: "dataItself",
                    border: [true, false, true, true],
                    text: { text: this.client.phoneNum ? { text: this.client.phoneNum.length > 30 ? this.client.phoneNum.slice(0,30)+'...' : this.client.phoneNum } : { text: this.noData } },
                    fontSize: this.checkFontSize(this.client.phoneNum, true),
                    colSpan: 2,
                    margin: [0, 0, 0, 6],
                  },
                  {},
                ],
                [
                  {},
                  {
                    colSpan: 2,
                    style: "dataName",
                    border: [true, true, true, false],
                    text: "Email address",
                    alignment: "left",
                    margin: [0, 4, 0, -2],
                  },
                  {},
                ],
                [
                  {},
                  {
                    style: "dataItself",
                    border: [true, false, true, true],
                    text: { text: this.client.email ? { text: this.client.email.length > 30 ? this.client.email.slice(0,30)+'...' : this.client.email } : { text: this.noData } },
                    fontSize: this.checkFontSize(this.client.email, true),
                    colSpan: 2,
                    margin: [0, 0, 0, 6],
                  },
                  {},
                ]
              ],
            },
            layout: {
              defaultBorder: false,
            },
          },
        ],

        footer: {
          text: "Powered by Complaia.com",
          style: "footer",
          link: "https://complaia.com",
        },

        styles: {
          footer: {
            alignment: "center",
            fontSize: 7,
            bold: true,
          },
          dataName: {
            fontSize: 8,
            margin: [0, 2, 0, -3],
          },
          dataItself: {
            margin: [0, 0, 0, 3],
          },
        },
      }
      pdfMake.createPdf(dd).download("Labeldd.pdf");
    },
    async updateClient()
    {
      if(this.editedClientData.name.length>0 && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.editedClientData.email))
      {
        try{
          await this.$store.state.userData.system.ref.collection("Clients").doc(this.client.id).update(this.editedClientData);
          await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/update/doc",{
            system: this.$store.state.userData.system.id,
            id: this.client.elasticSearch.docId,
            type: "clients",
            doc: {
              email: this.editedClientData.email,
              name: this.editedClientData.name,
              phoneNum: this.editedClientData.phoneNum
            }
          })
          this.$store.commit('setNotification',{
            show: true,
            head: "Successfully updated!",
            subheader: "Client has been updated.",
            success: true
          });
          // Add event to event list.
          this.$store.state.userData.system.ref.collection("Events").add(
          {
          user: {
              name: this.$store.state.user.displayName,
              photo: this.$store.state.user.photoURL
          },
          creationDate: moment().toDate(),
          eventName: `Client ${this.editedClientData.name} has been updated.`
          }).catch((error)=>
          {
          console.log(error);
          });
          setTimeout(()=>
          {
            this.$router.go()
          },1000)
          this.editMode = false;
          this.scrollToTop();
        }
        catch(error)
        {
          this.$store.commit('setNotification',{
            show: true,
            head: "Error",
            subheader: "Something went wrong during client update.",
            success: false
          });
          console.log(error);
        }
      }
    },
    scrollToTop()
    {
      const target = document.querySelector("#editName");
      target.scrollIntoView({ behavior: 'smooth', block: 'end'});
    },
    toggleEditMode()
    {
      this.editedClientData = JSON.parse(JSON.stringify(this.client));
      this.editMode = !this.editMode;
      this.scrollToTop();
    },
    async deleteClientFromDatabase()
    {
      // Show loader
      this.$store.state.globalLoader.show = true;
      try{
        // Delete client
        axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/delete/client",{
          system: this.$store.state.userData.system.id,
          docsToDelete: [this.client.elasticSearch.docId]
        }).catch((error)=>
        {
          console.log(error);
        })
        await this.$store.state.userData.system.ref.collection("Clients").doc(this.client.id).delete();
          this.$store.state.userData.system.ref.collection("Events").add(
          {
          user: {
            name: this.$store.state.user.displayName,
            photo: this.$store.state.user.photoURL
          },
          creationDate: moment().toDate(),
          eventName: `Deleted client ${this.client.name}.`
        }).catch((error)=>
        {
          console.log(error);
        })
        // Show notification
        this.$store.commit('setNotification',{
          show: true,
          head: "Successfully deleted!",
          subheader: "Client has been deleted.",
          success: true
        });
        // Hide loader and change page
        this.$store.state.globalLoader.show = false;
        this.$router.push("/dashboard/clients")

      }
      catch(error)
      {
        console.log(error)
        this.$store.state.globalLoader.show = false;
      }
    },
    showInGoogleMaps()
    {
      const uri = encodeURI(this.oneLineAddress);
      window.open(`http://maps.google.co.in/maps?q=${uri}`,'_blank');
    },
    call()
    {
      window.location.href = `tel:${this.client.phoneNum}`;
    },
    // Send Email
    sendEmail()
    {
      window.location.href = `mailto:${this.client.email}`;
    },
    // Go to previous page
    previousPage()
    {
      this.$router.push("/dashboard/clients")
    }
  }
}
</script>