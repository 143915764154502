<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
    <div @click="pushTo(complaint.id)" @mouseover="this.showManage = true, this.showIndex = index" @mouseleave="this.showManage = false, this.showIndex = null" v-for="(complaint, index) in complaints" :key="complaint.email" class="transition ease-in duration-150 cursor-pointer relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500">
      <div class="flex-1 min-w-0">
        <div class="focus:outline-none flex justify-between">
          <span class="text-sm font-medium text-gray-900">
            {{complaint.id}}
          </span>
          <transition enter-active-class="transition ease-in duration-150" enter-from-class="opacity-0" enter-to-class="opacity-100"  leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <span v-if="showManage && showIndex === index" class="text-sm ml-auto font-medium text-purple-500">
              Manage
            </span>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data()
  {
    return {
      showManage: false,
      showIndex: null,
    }
  },
  methods:
    {
      pushTo(id){
        this.$router.push(`/dashboard/complaints/edit?id=${id}`)
      }
    },
  props: ['complaints']
}
</script>