<template>
    <div id="Team" class="mt-2">
        <div class="flex justify-between items-center">
            
            <div class="flex flex-col">
                <h2 class="text-lg font-medium text-gray-900">Manage your team</h2>
                <p class="text-sm text-gray-500">Get started by selecting what you want to do.</p>
                             
            </div>
            <div class="flex items-center justify-center" v-if="this.$store.state.userData.permissions.teamManagement && this.$store.state.systemData.users.length < this.metadata.userAmount">
                <SortDropdown class="m4 z-50" v-if="search.phrase.length === 0"></SortDropdown>
                <button @click="showInviteModal = true" type="button" class="transition ease-in-out duration-150 inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                </button>
            </div>

        </div>
        <InviteToTeamModal :show="showInviteModal" @closeInviteModal="showInviteModal = false" @refreshList="refreshList = true"></InviteToTeamModal>
        <TeamList @listReseted="refreshList = false" :refreshList="refreshList"></TeamList>
    </div>
</template>
<script>
    import { PlusSmIcon } from '@heroicons/vue/solid';
    import TeamList from '@/components/TeamList.vue';
    import InviteToTeamModal  from '@/components/InviteToTeamModal.vue';
    export default {
        name: "Team",
        components: {TeamList, InviteToTeamModal, PlusSmIcon},
        data: function () {
            return {
                users: [],
                refreshList: false,
                search: {
                    phrase: ""
                },
                showInviteModal: false,
            }
        },
        methods: {
            resetSearch()
            {
                this.search.phrase = "";
            },
            searchForSystemUser(){
                // ELASTIC HERE
                alert('not ready yet');
            }
        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
            this.metadata = this.$store.state.userSubscription.items[0].price.product.metadata
        }
    };
</script>