<template>
    <div id="Clients" class="mt-2">
        <label for="search" class="block text-sm font-medium text-gray-700 mt-4">Search</label>
        <div class="flex justify-between items-center">
            
            <div class="flex items-center justify-center">
                <div class="flex items-center">
                    <div class="relative rounded-md shadow-sm">
                    <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                        <input type="text" v-model="search.phrase" name="search" id="search" class="focus:ring-purple-500 focus:border-purple-500 block w-full pl-10 text-sm border-gray-300 rounded-md" placeholder="Search for client.." />
                    </div>
                    
                </div>
                <div class="ml-2 flex justify-center items-center">
                    <button type="button" @click.prevent="searchForClients" :class="{'inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white': true, 'bg-purple-300': search.phrase.length === 0, 'bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500': search.phrase.length > 0}">
                        Search
                    </button>
                </div>                
            </div>
            <div class="ml-2 sm:ml-0 flex items-center justify-center">
                <button type="button" @click="showAddModal = true" class="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                    <PlusSmIcon class="h-6 w-6" aria-hidden="true" />
                </button>
                
            </div>
            
        </div>
        
        <div class="mt-4" v-if="search.active">
            <span class="inline-flex items-center py-0.5 pl-2 pr-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-700">
                Results of searching for: "{{search.lastResult.phrase}}" (Found: {{search.found.length}})
                <button @click.prevent="resetSearch()" type="button" class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-purple-400 hover:bg-purple-200 hover:text-purple-500 focus:outline-none focus:bg-purple-500 focus:text-white">
                <span class="sr-only">Remove small option</span>
                <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
                </svg>
                </button>
            </span>           
        </div>
        <Spinner v-if="loading"></Spinner>
        
        <clientList :clientList="search.active === false ? clientList : search.found"></clientList>
        <addClientModal :show="showAddModal" @closeClientModal="showAddModal = false"></addClientModal>
        <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false">
            <button type="button" @click.prevent="loadMoreClients" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Load more..</button>
        </div>
    </div>
    
    
</template>
<script>
    import axios from 'axios';
    import { SearchIcon, PlusSmIcon  } from '@heroicons/vue/solid';
    import addClientModal  from '@/components/addClientModal.vue';
    import Spinner  from '@/components/loadingSpinner.vue';
    import {db} from "@/firebase/gfbconf.js"
    import ClientList  from '@/components/ClientList.vue';
    
    export default {
        name: "Clients",
        components: {SearchIcon,PlusSmIcon, addClientModal, ClientList, Spinner},
        data: function () {
            return {
                loading: false,
                askDelete: false,
                queryLimitReached: false,
                queryLimit: 10,
                search: {
                    active: false,
                    phrase: "",
                    found: [],
                    lastResult: null
                },
                lastLoaded: null,
                showAddModal: false,
                dbListener: null,
                clientList: []
            }
        },
        methods: {
            async searchForClients()
            {
                if(this.search.phrase.length>0)
                {
                    this.loading = true;
                    const result = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/search/client", {
                        system: this.$store.state.userData.system.id,
                        phrase: this.search.phrase
                    }).catch((error)=>
                    {
                        console.log(error);
                    });
                    this.loading = false;
                    if(result.data.status === "ok")
                    {
                        this.search.active = true;
                        this.search.lastResult = result.data;
                        this.search.found = result.data.result;
                    }
                }
            },
            // Reset searchphrase
            resetSearch()
            {
                this.search.active = false
                this.search.phrase = "";
            },
            continueListening()
            {
                this.queryLimit = this.queryLimit + 10;
                this.dbListener = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").orderBy("creationDate", "desc").limit(this.queryLimit)
                .onSnapshot((querySnapshot) => {
                    this.clientList = [];
                    if(querySnapshot.docs.length<this.queryLimit)
                    {
                        this.queryLimitReached = true;
                    }
                    else
                    {
                        this.queryLimitReached = false;
                    }
                    querySnapshot.forEach((doc) => {
                        this.clientList.push(doc.data());
                    });
                });
            },
            loadMoreClients()
            {
                this.dbListener();
                this.continueListening();      
            }
        },
        created()
        {
            this.dbListener = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").orderBy("creationDate", "desc").limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.clientList = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {
                    this.clientList.push(doc.data());
                    this.lastLoaded = doc.data().creationDate;
                });
            });
        },
        beforeUnmount() {
            this.dbListener();
        }
    };
</script>