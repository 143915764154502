<template>
    <div class="mt-8 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
          <div class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
            <div v-if="selected.length > 0" class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 sm:left-16">
              <!-- <button type="button" class="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Print Label</button> -->
              <button @click.prevent="deleteAsk = true" type="button" class="inline-flex items-center rounded border border-red-400 bg-red-500 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30">Delete all</button>
            </div>
            <table class="min-w-full table-fixed divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500 sm:left-6" :checked="indeterminate || selected.length === clientList.length" :indeterminate="indeterminate" @change="selected = $event.target.checked ? clientList.map((client) => client.id) : []" />
                  </th>
                  <th scope="col" class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">Name</th>
                  <th scope="col" class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">E-mail</th>
                  <th scope="col" class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">Phone</th>
                  <th scope="col" class="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">Info</th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Add new</span>
                  </th>
                  <!-- <th v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingCustomers" scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">View</span>
                  </th> -->
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(client,clIndx) in clientList" :key="clIndx" class="hover:bg-gray-50">
                  <td class="relative w-12 px-6 sm:w-16 sm:px-8">
                    <div v-if="selected.includes(client.id)" class="absolute inset-y-0 left-0 w-0.5 bg-purple-400"></div>
                    <input type="checkbox" class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500 sm:left-6" :value="client.id" v-model="selected"/>
                  </td>
                  <!-- <td :class="['whitespace-nowrap py-auto pr-3 my-auto text-sm font-medium flex items-center']">
                    <UserGroupIcon class="h-4 w-4 mr-3 text-purple-500" v-if="client.type === 'Company'" />
                    <UserIcon class="h-4 w-4 mr-3 text-purple-500" v-if="client.type === 'Client'" /> {{client.name}}
                  </td> -->
                  <td :class="['whitespace-nowrap py-4 pr-3 text-sm font-medium']">
                    <div class="flex items-center">
                      <UserGroupIcon class="h-4 w-4 mr-3 text-purple-500 flex justify-center items-center" v-if="client.type === 'Company'" />
                      <UserIcon class="h-4 w-4 mr-3 text-purple-500" v-if="client.type === 'Client'" /> 
                      {{client.name.length > 35 ? client.name.substring(0,35) + '...' : client.name}}
                    </div>
                  </td>
                  <td :class="['whitespace-nowrap py-4 pr-3 text-sm font-xs']">
                    <a class="hover:text-purple-400" :href="'mailto:'+client.email">{{client.email.length > 35 ? client.email.substring(0,35) + '...' : client.email}}</a>
                  </td>
                  <td :class="['whitespace-nowrap py-4 pr-3 text-sm font-xs']">
                    <a class="hover:text-purple-400" :href="'tel:'+client.phoneNum" v-if="client.phoneNum>0">{{client.phoneNum}}</a>
                  </td>
                  <td :class="['whitespace-nowrap py-4 pr-3 text-sm font-xs']">
                    <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800" v-if="client.complaints.length === 0"> No complaints yet</span>
                    <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800" v-if="client.complaints.length > 0"> {{client.complaints.length}} complaints </span>
                  </td>
                  <!-- <td class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <router-link :to="'clients/view/'+client.id" class="text-purple-600 hover:text-purple-900"
                      >Manage<span class="sr-only"></span></router-link>
                  </td>
                  <td v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingCustomers"  class="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button @click="pushNewComplaint(client)" class="text-purple-600 hover:text-purple-900">
                      Add new complaint<span class="sr-only"></span></button>
                  </td> -->
                  <td  class="relative flex justify-end whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <div class="flex justify-end">
                      <router-link :to="'clients/view/'+client.id" class="text-purple-600 hover:text-purple-900"
                      >
                      <div class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">Manage</div>
                      <span class="sr-only"></span></router-link>
                      <div v-if="this.$store.state.userData.systemCreator || this.$store.state.userData.permissions.editingCustomers" class="text-purple-600 hover:text-purple-900"
                      >
                      <button @click.prevent="pushNewComplaint(client)" type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500">Add new complaint</button>
                      <span class="sr-only"></span></div>
                      </div>                                                      
                  </td>
        
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <emptyState class="mt-2 mb-2" v-if="clientList.length === 0"></emptyState>
    <askForDelete v-if="deleteAsk" :modalData="modalData" @accept="deleteClientFromDatabase" @closeModal="deleteAsk = false"></askForDelete>
    
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    import {db} from "@/firebase/gfbconf.js"
    import askForDelete from '@/components/AlertModal.vue';
    import emptyState from '@/components/EmptyState.vue';
    import { UserIcon, UserGroupIcon } from '@heroicons/vue/solid';
    export default {
        name: "ClientList",
        data: function () {
            return {
                deleteAsk: false,
                checked: false,
                selected: [],
                modalData: {
                  headerText: "Delete client",
                  bodyText: "Are you sure to delete selected client? Data will be removed permanently.",
                  acceptButtonText: "Agree"
                }
            }
        },
        components: {UserIcon, UserGroupIcon, askForDelete,emptyState},
        props: ["clientList"],
        methods: {
          pushNewComplaint(client){
            const clientId = client.id
            this.$router.push(`/dashboard/complaints/new?client=${clientId}`)
          },
          checkClientBySelection(id)
          {
            for(let i=0;i<this.clientList.length; i++)
            {
              if(this.clientList[i].id === id)
              {
                return this.clientList[i];
              }
            }
          },
          async deleteClientFromDatabase()
          {
            // Create event to feed
            if(this.selected.length === 1)
            {
                let client = this.checkClientBySelection(this.selected[0]);
                axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/delete/client",{
                  system: this.$store.state.userData.system.id,
                  docsToDelete: [client.elasticSearch.docId]
                }).catch((error)=>
                {
                  console.log(error);
                });
                this.$store.state.userData.system.ref.collection("Events").add(
                {
                user: {
                  name: this.$store.state.user.displayName,
                  photo: this.$store.state.user.photoURL
                },
                creationDate: moment().toDate(),
                eventName: `Deleted client ${client.name}.`
              }).catch((error)=>
              {
                console.log(error);
              })
            }
            else
            {
                let client = this.checkClientBySelection(this.selected[0]);
                this.$store.state.userData.system.ref.collection("Events").add(
                {
                user: {
                  name: this.$store.state.user.displayName,
                  photo: this.$store.state.user.photoURL
                },
                creationDate: moment().toDate(),
                eventName: `Deleted client ${client.name} and ${this.selected.length-1} more.`
              }).catch((error)=>
              {
                console.log(error);
              });
            }

            let elasticListOfClientsToDelete = [];
            // Delete clients from Db.
            for(let i=0;i<this.selected.length;i++)
            {
              let client = this.checkClientBySelection(this.selected[i]);
              elasticListOfClientsToDelete.push(client.elasticSearch.docId)
            }
            axios.post("https://europe-central2-complaia.cloudfunctions.net/api/elastic/delete/client",{
              system: this.$store.state.userData.system.id,
              docsToDelete: elasticListOfClientsToDelete
            }).catch((error)=>
            {
              console.log(error);
            })
            for(let i=0; i<this.selected.length; i++)
            {
              await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Clients").doc(this.selected[i]).delete().catch((error)=>{
                console.log(error);
              })
            }
            this.$store.commit('setNotification',{
              show: true,
              head: "Successfully deleted!",
              subheader: "Client(s) has been deleted.",
              success: true
            });
            this.selected = [];
          }
        },
        computed:{
            indeterminate()
            {
                const val = this.selected.length > 0 && this.selected.length < this.clientList.length;
                return val;
            }
        },
    };
</script>