<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed inset-0 overflow-hidden z-50" @close="open = false">
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay class="absolute inset-0 bg-black opacity-40" />

        <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
          <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="pointer-events-auto w-screen max-w-md">
              <form class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                <div class="h-0 flex-1 overflow-y-auto">
                  <div class="bg-gradient-to-r from-indigo-600 to-purple-600 py-6 px-4 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-lg font-medium text-white"> Invite new user </DialogTitle>

                    </div>
                    <div class="mt-1">
                      <p class="text-sm text-blue-300">Invite a new user to manage your complaints.</p>
                    </div>
                  </div>
                  <div class="flex flex-1 flex-col justify-between">
                    <div class="divide-y divide-gray-200 px-4 sm:px-6">
                      <div class="space-y-6 pt-6 pb-5">
                        <div>
                          <label for="invitation-email" :class="{'block text-sm font-medium text-gray-900': true, 'text-red-600': (!checkEmail && invitation.email.length>0) || emailError.error}"> E-mail* </label>
                          <div class="mt-1 mb-4">
                            <input type="text" name="invitation-email" id="invitation-email" v-model="invitation.email" :class="{'block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm': true, 'border-red-500 focus:ring-red-500 focus:border-red-500 ': (!checkEmail && invitation.email.length>0) || emailError.error}" />
                            <p class="text-sm text-red-600 pt-1" v-if="emailError.error && emailError.type === 'emailExists'">The user's email address is already in use by another system</p>
                            <p class="text-sm text-red-600 pt-1" v-if="emailError.error && emailError.type === 'alreadyInvited'">The user's email address is already invited to your or another system</p>
                          </div>
                          <label for="invitation-firstname" :class="{'block text-sm font-medium text-gray-900': true, 'text-red-600': !checkFirstName && invitation.firstName.length>0}"> First name* </label>
                          <div class="mt-1 mb-4">
                            <input type="text" name="invitation-firstname" id="invitation-firstname" v-model="invitation.firstName" :class="{'block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm': true, 'border-red-500 focus:ring-red-500 focus:border-red-500 ': !checkFirstName && invitation.firstName.length>0}" />
                          </div>
                          <label for="invitation-lastname" :class="{'block text-sm font-medium text-gray-900': true, 'text-red-600': !checkLastName && invitation.lastName.length>0}"> Last name* </label>
                          <div class="mt-1 mb-4">
                            <input type="text" name="invitation-lastname" id="invitation-lastname" v-model="invitation.lastName" :class="{'block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm': true, 'border-red-500 focus:ring-red-500 focus:border-red-500 ': !checkLastName && invitation.lastName.length>0}" />
                          </div>
                          <label for="invitation-phonenumber" :class="{'block text-sm font-medium text-gray-900': true }"> Phone number </label>
                          <div class="mt-1">
                            <input type="text" onkeypress='return event.charCode >= 48 && event.charCode <= 57 || event.charCode === 43 || event.charCode === 45' name="invitation-phonenumber" id="invitation-phonenumber" v-model="invitation.phoneNumber" :class="{'block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm': true}" />
                          </div>
                        </div>
                        


                      </div>
                      <div class="pt-4 pb-6">
                        <div class="mt-4 flex text-sm">
                          <div class="group inline-flex items-center text-gray-500 cursor-help">
                            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400 cursor-help" aria-hidden="true" />
                            <span class="ml-2"> This user will be able to manage your complaints. </span>
                          </div>
                        </div>
                        <div class="mt-4 flex text-sm">
                          <div class="group inline-flex items-center text-gray-500 cursor-help">
                            <CheckCircleIcon class="h-5 w-5 text-gray-400 cursor-help" aria-hidden="true" />
                            <span class="ml-2"> User will receive link to connect with your system. </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex flex-shrink-0 justify-end px-4 py-4 items-center">
                  <button @click.prevent="emitClose" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Cancel</button>
                  <button @click.prevent="invite" :disabled="sent === true" :class="{'ml-4 inline-flex justify-center rounded-md border border-transparent bg-purple-400 py-2 px-4 text-sm font-medium text-white shadow-sm cursor-default': true, 'bg-purple-600 hover:bg-purple-700 cursor-pointer focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2': this.invitation.email.length > 1 && checkEmail && this.invitation.firstName.length > 1 && checkFirstName && this.invitation.lastName.length > 1 && checkLastName}">Invite</button>
                </div>
              </form>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    // import OrangeAlert from '@/components/OrangeAlert.vue';
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { QuestionMarkCircleIcon,CheckCircleIcon } from '@heroicons/vue/solid'
    import {db, auth} from "@/firebase/gfbconf.js"
    export default {
        name: "InviteToTeamModal",
        components: {
            Dialog,
            DialogOverlay,
            DialogTitle,
            TransitionChild,
            TransitionRoot,
            // OrangeAlert,
            QuestionMarkCircleIcon,
            CheckCircleIcon,
        },
        data: function () {
            return {
                sent: false,
                invitation: {
                    email: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: ""
                },
                emailError: {
                  error: false,
                  type: ""
                }
            }
        },
        methods: {

            async invite(){
              this.sent = true
              if(this.invitation.email.length > 0 && this.checkEmail && this.invitation.firstName.length > 1 && this.checkFirstName && this.invitation.lastName.length > 1 && this.checkLastName && await this.checkEmailExists()){
              let d = new Date().getTime();
                d += performance.now();
              let invitationId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c)
              {
                let r = (d + Math.random()*16)%16 | 0;
                d = Math.floor(d/16);
                return (c=='x' ? r : (r&0x3|0x8)).toString(16);
              });
              let info = await this.$store.state.userData.system.ref.get()
              let company = info.data().company
                const data = {
                  email: this.invitation.email,
                  name: this.invitation.firstName,
                  surname: this.invitation.lastName,
                  phoneNumber: this.invitation.phoneNumber,
                  system: this.$store.state.userData.system.id,
                  used: false,
                  company: company
                }
                try
                  {
                      const result = await db.collection('Invitations').doc(invitationId).set(data);
                      this.clearData();
                      this.sent = false;
                      this.$emit('refreshList')
                      this.$emit("closeInviteModal");
                      const email = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/sendEmail", {
                      // const email = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/sendEmail", {
                        attendantEmail: this.$store.state.user.email,
                        to: data.email,
                        messageType: "teamInvite",
                        payload: {
                          inviterName: this.$store.state.user.displayName,
                          companyName: this.$store.state.systemData.company.name,
                          invitedName: data.name,
                          invitedSurname: data.surname,
                          invitationLink: `https://app.complaia.com/invitation?id=${invitationId}`
                        }
                      })
                      if(email.data.status === "OK") {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Success!",
                            subheader: `The invitation was successfully sent.`,
                            success: true
                        });
                        this.$store.state.userData.system.ref.collection("Events").add(
                            {
                            user: {
                                name: this.$store.state.user.displayName,
                                photo: this.$store.state.user.photoURL
                            },
                            creationDate: moment().toDate(),
                            eventName: `The invitation has been sent.`
                        }).catch((error)=>
                        {
                        console.log(error);
                        });
                      }
                      return result;
                  }
                catch (error)
                  {
                      console.log('Couldnt add invitation to db. ', error);
                      this.$emit("closeInviteModal");
                      this.sent = false
                      setTimeout(()=>{
                      this.$store.commit('setNotification',{
                            show: true,
                            head: "Failure.",
                            subheader: `Invitation has not been sent. Try again later.`,
                            success: false
                        });    
                      },600);   
                      return 0;
                  }
              } else {
                this.sent = false
              }
            },
            clearData()
            {
                this.invitation = {
                    email: "",
                    firstName: "",
                    lastName: "",
                    phoneNumber: ""
                };
                this.emailError = {
                  error: false,
                  type: '',
                }
            },
            // Reset searchphrase
            emitClose()
            {
                this.clearData();
                this.$emit("closeInviteModal");
            },
            isNumber(event) {
              event.preventDefault()
            },
           async checkEmailExists() {
            try{
              const result = await auth.fetchSignInMethodsForEmail(this.invitation.email)
              let emailExists = false
              let alreadyInvited = false
              if(result.length > 0) {
                emailExists = true
              }
              this.emailError.error = emailExists
              this.emailError.type = emailExists ? 'emailExists' : ''
              if(!emailExists) {
                const getInvitations = await db.collection('Invitations').where('email', '==', this.invitation.email).get()
                if(getInvitations.empty) {
                  this.emailError.error = false
                  this.emailError.type = ''
                } else {
                  this.emailError.error = true
                  this.emailError.type = 'alreadyInvited'
                  alreadyInvited = true
                }
              } 
              return !emailExists && !alreadyInvited ? true : false
            } catch(error) {
              console.log(error);
              return false
            }
              
            }
        },
        computed:{
           checkEmail()
            {
                if(this.invitation.email.length > 1)
                {
                    if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.invitation.email))
                    {
                        return true
                    }
                    else
                    {
                        return false
                    }
                }
                else
                {
                    return true
                }
            },
          checkFirstName(){
            if(this.invitation.firstName.length > 1){
              return true
            }else{
              return false
            }
          },
          checkLastName(){
            if(this.invitation.lastName.length > 1){
              return true
            }else{
              return false
            }
          },
        },
        created(){
        }
    };
</script>