<template>
    <div v-if="client !== null" id="editClient" class="mt-2">
        <ClientInfo :client="client"></ClientInfo>
    </div>
</template>
<script>
        import ClientInfo from '@/components/ClientInfo.vue';
        export default {
        name: "EditClient",
        components: {ClientInfo},
        data: function () {
            return {
                client: null,
            }
        },
        methods: {
            async getClientData()
            {
                const userQueryResult = await this.$store.state.userData.system.ref.collection("Clients").doc(this.$route.params.id).get();
                if(userQueryResult.exists)
                {
                    this.client = userQueryResult.data();
                }
                else
                {
                    this.$router.push("/error404")
                }
            }
        },
        created()
        {
            this.getClientData();
        }
    };
</script>